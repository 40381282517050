import jQuery from 'jquery';

;(function ($, document, window, undefined) {
    const STATUS_PENDING = 'pending',
        STATUS_SUCCESS = 'success',
        STATUS_DUPLICATE = 'duplicate',
        STATUS_FAILURE = 'failure';

    const pluginName = 'asyncScanTable',
        defaults = {
            statusField: 'created_at',
            focusFieldName: null,
            pendingClasses: 'fa fa-sync fa-spin text-secondary',
            successClasses: 'fa fa-check text-success',
            duplicateClasses: 'fa fa-clone text-danger',
            failureClasses: 'fa fa-times text-danger',
            requestObjectName: 'source_sample',
            requestEndpoint: '/source_samples.json',
            requestMethod: 'POST',
            disableSounds: false,
            enableDelete: false,
            deleteEndpoint: null,
            deleteTemplate: '<a title="Delete" data-confirm="Are you sure?" class="btn text-danger" rel="nofollow" data-method="delete"><i class="fas fa-trash"></i></a>',
            onSubmit: function (new_sample) {
                return true;
            },
            onSuccess: function (row, data) {
                return true;
            },
        };

    function Plugin(table_element, options) {
        this.document = document;
        this.window = window;
        this.element = $(table_element);
        this.options = $.extend({}, defaults, options);

        this.init();
    }

    Plugin.prototype.init = function () {
        this.registerSampleTabHandler();
    };

    // A really lightweight plugin wrapper around the constructor,
    // preventing against multiple instantiations
    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'plugin_' + pluginName)) {
                $.data(this, 'plugin_' + pluginName,
                    new Plugin(this, options));
            }
        });
    };

    Plugin.prototype.playSuccessSound = function () {
        if (this.options.disableSounds) return;

        $('audio.success_sound')[0].currentTime = 0;
        $('audio.success_sound')[0].play();
    };

    Plugin.prototype.playFailureSound = function () {
        if (this.options.disableSounds) return;

        $('audio.failure_sound')[0].currentTime = 0;
        $('audio.failure_sound')[0].play();
    };

    Plugin.prototype.newSampleFields = function () {
        return this.element.find('#new_sample :input');
    };
    Plugin.prototype.focusField = function () {
        if (this.options.focusFieldName) {
            return this.element.find(`input[name="${this.options.focusFieldName}"]`).first()
        } else {
            return this.newSampleFields().first();
        }
    };

    Plugin.prototype.newSampleData = function () {
        let new_sample = {};
        let fields = this.newSampleFields();

        for (let field of fields) {
            const field_name = $(field).attr('name');
            const field_val = $(field).val().trim();

            // Handle multi-value fields
            if (field_name.includes('[]')) {
                const arr_field_name = field_name.replace('[]', '');
                if (!new_sample.hasOwnProperty(arr_field_name)) {
                    new_sample[arr_field_name] = [];
                }
                new_sample[arr_field_name].push(field_val);
            } else {
                new_sample[field_name] = field_val;
            }

            if ($(field).attr('type') !== 'hidden') {
                $(field).val('');
            }
        }

        return new_sample;
    };

    Plugin.prototype.tableHeader = function () {
        let header_order = [];

        this.element.find('thead th').each(function (idx, elem) {
            header_order.push($(elem).data('field'));
        });

        return header_order;
    };

    Plugin.prototype.templateRow = function () {
        return this.element.find('#sample_row_template');
    };

    Plugin.prototype.newSampleRow = function () {
        return this.element.find('#new_sample');
    };

    Plugin.prototype.sampleRows = function (barcode) {
        return this.element.find(`tbody > tr[data-barcode="${barcode}"]`);
    };

    Plugin.prototype.pendingSampleRow = function (barcode) {
        return this.sampleRows(barcode).filter(`[data-status="${STATUS_PENDING}"]`);
    };

    Plugin.prototype.addNewSampleToTable = function (sample) {
        const header_order = this.tableHeader();
        let new_row = this.templateRow().clone();

        new_row.removeAttr('id');
        new_row.removeAttr('class');
        new_row.attr('data-name', sample.name);
        new_row.attr('data-barcode', sample.barcode);
        new_row.attr('data-status', STATUS_PENDING);

        new_row.children('td').each(function (idx, elem) {
            let val = sample[header_order[idx]];

            if (val && val.constructor === Array) val = val.join(', ');

            $(elem).text(val);
        });

        new_row.removeClass('d-none');
        new_row.insertAfter(this.newSampleRow());
    };

    Plugin.prototype.setRowStatusIcon = function (row, new_status) {
        $(row).attr('data-status', new_status);

        let target_elem = $(row).find('span.status');
        target_elem.empty();

        let new_icon = this.document.createElement('i');
        switch (new_status) {
            case STATUS_PENDING:
                $(new_icon).addClass(this.options.pendingClasses);
                break;
            case STATUS_SUCCESS:
                $(new_icon).addClass(this.options.successClasses);
                break;
            case STATUS_DUPLICATE:
                $(new_icon).addClass(this.options.duplicateClasses);
                break;
            case STATUS_FAILURE:
                $(new_icon).addClass(this.options.failureClasses);
                break;
        }

        target_elem.append(new_icon);
    };

    Plugin.prototype.setRowStatus = function (row, content, overwrite = true) {
        const header_order = this.tableHeader();
        const status_index = header_order.indexOf(this.options.statusField) + 1;
        let elem = $(row).children(`td:nth-child(${status_index})`);

        if (!overwrite) {
            elem.append(content);
        } else {
            elem.html(content);
        }
    };

    Plugin.prototype.setRowStatusPopover = function (row, popover_text) {
        let status_column = $(row).children('td:first-child');

        if (status_column.data('content')) {
            const new_text = status_column.data('content') + '\n' + popover_text;
            status_column.attr('data-content', new_text);
        } else {
            status_column.attr('data-content', popover_text);
            status_column.popover({
                placement: 'right',
                trigger: 'hover',
                content: popover_text,
            });
        }
    };

    Plugin.prototype.setRowFields = function (row, data) {
        const header_order = this.tableHeader();

        for (let key in data) {
            if (header_order.includes(key)) {
                const field_idx = header_order.indexOf(key) + 1;
                let field = row.children(`td:nth-of-type(${field_idx})`);

                field.html(data[key]);
            }
        }
    };

    Plugin.prototype.setRowDelete = function (row, url) {
        const header_order = this.tableHeader();
        const field_idx = header_order.indexOf('options') + 1;
        let field = row.children(`td:nth-of-type(${field_idx})`);
        let deleteButton = $(this.options.deleteTemplate);

        let redirectUrl = url;

        if (url.includes('?')) {
            redirectUrl += "&redirect_to=" + document.location;
        } else {
            redirectUrl += "?redirect_to=" + document.location;
        }

        deleteButton.attr('href', redirectUrl);
        field.append(deleteButton);
    };

    Plugin.prototype.handleUploadSuccess = function (sample, data) {
        const status_at = data[this.options.statusField];

        let sample_row = this.pendingSampleRow(sample.barcode);

        this.setRowFields(sample_row, data);
        this.setRowStatusIcon(sample_row, STATUS_SUCCESS);
        this.setRowStatus(sample_row, status_at);

        if (this.options.enableDelete) {
            if (data.url) {
                this.setRowDelete(sample_row, data.url)
            } else if (this.options.deleteEndpoint) {
                this.setRowDelete(sample_row, `${this.options.deleteEndpoint}/${data.id}`)
            } else {
                this.setRowDelete(sample_row, `${this.options.requestEndpoint}/${data.id}`)
            }
        }

        // Call the custom success handler. Does nothing by default
        this.options.onSuccess(sample_row, data);
        this.playSuccessSound();

        return true;
    };

    Plugin.prototype.tableHeaderTitleForField = function (field_name) {
        // Capitalize the first char of the field_name so we can use the
        // value as a fallback.
        field_name = field_name.replace(/^\w/, c => c.toUpperCase());
        let field_title = field_name

        this.element.find('thead th').each(function (idx, elem) {
            if ($(elem).data('field') === field_name) field_title = $(elem).text();
        });

        return field_title;
    };

    Plugin.prototype.handleUploadFailure = function (sample, errors) {
        console.log(errors);

        let pending_rows = this.pendingSampleRow(sample.barcode);

        let boundCallback = (function (idx, row) {
            this.setRowStatus(row, `<span class="text-danger">NOT SAVED</span><br/>`, false);
            for (let field_name in errors) {
                if (errors.hasOwnProperty(field_name)) {
                    for (let error of errors[field_name]) {
                        if (error === 'has already been taken') {
                            this.setRowStatusIcon(row, STATUS_DUPLICATE);
                        } else {
                            this.setRowStatusIcon(row, STATUS_FAILURE);
                        }

                        const header_title = this.tableHeaderTitleForField(field_name);
                        const error_content = `<span class="text-danger">Error: ${header_title} ${error}</span><br/>`;
                        this.setRowStatus(row, error_content, false);
                        this.setRowStatusPopover(row, `${header_title}: ${error}`)
                    }
                }
            }
        }).bind(this);

        $(pending_rows).each(boundCallback);

        this.playFailureSound();

        return true;
    };

    Plugin.prototype.getCSRFToken = function () {
        return $('meta[name=csrf-token]').attr('content');
    };

    Plugin.prototype.uploadSample = function (sample) {
        let payload = {};
        payload[this.options.requestObjectName] = sample;

        fetch(this.options.requestEndpoint, {
            method: this.options.requestMethod,
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-CSRF-Token': this.getCSRFToken(),
            },
            redirect: 'follow',
            body: JSON.stringify(payload)
        }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                response.json().then((json_data) => {
                    this.handleUploadFailure(sample, json_data);
                }).catch(() => {
                    return Promise.reject(new Error("Unable to parse response body."));
                });

                return Promise.reject('Unable to create sample record.');
            }
        }).then((data) => {
            this.handleUploadSuccess(sample, data);
        }).catch((error) => {
            console.log('Error:', error)
        });
    };

    Plugin.prototype.registerSampleTabHandler = function () {
        let last_field = this.newSampleFields().not('[type="hidden"]').last();

        let boundCallback = (function (e) {
            const TAB_KEY_CODE = 9;
            const key_code = e.keyCode || e.which;
            if (key_code === TAB_KEY_CODE) {
                e.preventDefault();

                const new_sample = this.newSampleData();

                this.options.onSubmit(new_sample);

                this.addNewSampleToTable(new_sample);
                this.uploadSample(new_sample);

                this.focusField().focus();
            }
        }).bind(this);

        last_field.keydown(boundCallback);
    }
})(jQuery, document, window);
